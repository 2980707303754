import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Flexbox, Grid } from '@mindmatch/ui';
import Layout from 'components/Layout';
import LayoutContainer from 'components/LayoutContainer';
import Intro from 'components/Intro';
import Timeline from 'components/Timeline';
import Card from 'components/Card';
import Heading from 'components/Heading';
import TextImage from 'components/TextImage';
import Text from 'components/Text';
import FeaturedSection from 'components/FeaturedSection';
import CustomerLogos from 'components/CustomerLogos';

import { ReactComponent as Benefits1Icon } from 'images/icons/cv.svg';
import { ReactComponent as Benefits2Icon } from 'images/icons/benefits2.svg';
import { ReactComponent as Benefits3Icon } from 'images/icons/benefits3.svg';

const IndexPage = ({ data, ...props }) => (
  <Layout theme="light">
    <main>
      <Intro />
      <LayoutContainer>
        <Text as="h1" fontSize={[26, 30, 30]} textAlign="center" pt={30} mb={60} id="product">
          Make the whole Internet your recruitment platform with a single click
        </Text>
        <br />
        <section>
          <Heading as="h2" mb={'5'}>
            How it works
          </Heading>
          <TextImage reverse>
            <TextImage.Body>
              <Timeline>
                <Timeline.Item>
                  <Timeline.ItemIcon>1</Timeline.ItemIcon>
                  <h3>Create a new job</h3>
                  <Text as="p" color="var(--text-gray)">
                    Simply add your jobs on MindMatch and our engine will distribute them across the
                    entire web.
                  </Text>
                </Timeline.Item>
                <Timeline.Item>
                  <Timeline.ItemIcon>2</Timeline.ItemIcon>
                  <h3>Choose your budget</h3>
                  <Text as="p" color="var(--text-gray)">
                    Depending on your hiring needs, we help you pick the right starting budget. This
                    can be used for all your jobs.
                  </Text>
                </Timeline.Item>
                <Timeline.Item>
                  <Timeline.ItemIcon>3</Timeline.ItemIcon>
                  <h3>Receive candidates</h3>
                  <Text as="p" color="var(--text-gray)">
                    No matter which channel, all applicants come in through MindMatch, where you can
                    easily screen them or forward them to your ATS.
                  </Text>
                </Timeline.Item>
              </Timeline>
            </TextImage.Body>
            <TextImage.Image>
              <div style={{ width: '100%' }}>
                <Img fluid={data.dashboardImage.childImageSharp.fluid} />
              </div>
            </TextImage.Image>
          </TextImage>
        </section>

        <FeaturedSection as="section" id="features">
          <LayoutContainer>
            <Text as="h1" fontSize={[26, 30, 30]} textAlign="center" mt={0} mb={60}>
              Features
            </Text>
            <article>
              <TextImage>
                <TextImage.Body>
                  <Heading as="h2" mb="3" textAlign="left">
                    Job Ad Distribution
                  </Heading>
                  <h1>Advertise your jobs all over the web</h1>
                  <Text as="p" color="var(--text-gray)">
                    Gain access to all job seekers on active and passive channels through
                    performance marketing
                  </Text>
                </TextImage.Body>
                <TextImage.Image>
                  <div style={{ width: '100%' }}>
                    <Img fluid={data.jobAdDistributionImage.childImageSharp.fluid} />
                  </div>
                </TextImage.Image>
              </TextImage>
            </article>

            <article>
              <TextImage reverse>
                <TextImage.Body>
                  <Heading as="h2" mb="3" textAlign="left">
                    Flexible Posting & Budgeting
                  </Heading>
                  <h1>Be in complete control of your campaigns every step of the way</h1>
                  <Text as="p" color="var(--text-gray)">
                    Choose which jobs are being promoted anytime by simply toggling them active or
                    inactive.
                  </Text>
                </TextImage.Body>
                <TextImage.Image>
                  <div style={{ width: '100%' }}>
                    <Img fluid={data.flexiblePostingImage.childImageSharp.fluid} />
                  </div>
                </TextImage.Image>
              </TextImage>
            </article>

            <article>
              <TextImage>
                <TextImage.Body>
                  <Heading as="h2" mb="3" textAlign="left">
                    Recruiting analytics
                  </Heading>
                  <h1>Stay ahead of the game through the analytics dashboard</h1>
                  <Text as="p" color="var(--text-gray)">
                    Gain key insights into your job's performance through the analytics dashboard,
                    which shows views, cost per applications and conversion rates.
                  </Text>
                </TextImage.Body>
                <TextImage.Image>
                  <div style={{ width: '100%', margin: '50px 0' }}>
                    <Img fluid={data.recruitingAnalyticsImage.childImageSharp.fluid} />
                  </div>
                </TextImage.Image>
              </TextImage>
            </article>
          </LayoutContainer>
        </FeaturedSection>

        <section style={{ marginTop: '30px' }}>
          <Heading as="h2" mb="4">
            Experience it first hand
          </Heading>

          <Grid templateColumns="1fr 1fr 1fr" gap="55px">
            <Card>
              <Flexbox justifyContent="center" style={{ marginBottom: '40px' }}>
                <Benefits1Icon />
              </Flexbox>
              <h3>Receive more candidates</h3>
              <Text as="p" color="var(--text-gray)">
                Utilising active and passive channels, MindMatch increases the visibilty of your
                jobs and draws in more applicants.
              </Text>
            </Card>

            <Card>
              <Flexbox justifyContent="center" style={{ marginBottom: '40px' }}>
                <Benefits2Icon />
              </Flexbox>
              <h3>Reduce your workload</h3>
              <Text as="p" color="var(--text-gray)">
                Only add your job once! The platform will allocate your budget efficiently across
                the most successful channels.
              </Text>
            </Card>

            <Card>
              <Flexbox justifyContent="center" style={{ marginBottom: '40px' }}>
                <Benefits3Icon />
              </Flexbox>
              <h3>Increase Flexibility</h3>
              <Text as="p" color="var(--text-gray)">
                You can add, play and pause jobs based on your needs, giving you full flexibilty.
              </Text>
            </Card>
          </Grid>
        </section>

        <section style={{ marginTop: '100px' }}>
          <Heading as="h2" mb="5">
            Join our growing community
          </Heading>
          <CustomerLogos>
            <CustomerLogos.Item>
              <Img fixed={data.amboss.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.honeypot.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.kloeckner.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.hyperloopOne.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.pulsepoint.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.raisin.childImageSharp.fixed} />
            </CustomerLogos.Item>
            <CustomerLogos.Item>
              <Img fixed={data.smunch.childImageSharp.fixed} />
            </CustomerLogos.Item>
          </CustomerLogos>
        </section>
      </LayoutContainer>
    </main>
  </Layout>
);

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const customerLogo = graphql`
  fragment customerLogo on File {
    childImageSharp {
      fixed(width: 80, quality: 100) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`;

export const query = graphql`
  query {
    dashboardImage: file(relativePath: { eq: "dashboard.png" }) {
      ...fluidImage
    }
    jobAdDistributionImage: file(relativePath: { eq: "features/job-ad-distribution.png" }) {
      ...fluidImage
    }
    flexiblePostingImage: file(relativePath: { eq: "features/flexible-posting.png" }) {
      ...fluidImage
    }
    recruitingAnalyticsImage: file(relativePath: { eq: "features/recruiting-analytics.png" }) {
      ...fluidImage
    }

    # Customer logos
    amboss: file(relativePath: { eq: "customer-logos/amboss.png" }) {
      ...customerLogo
    }
    honeypot: file(relativePath: { eq: "customer-logos/honeypot.png" }) {
      ...customerLogo
    }
    kloeckner: file(relativePath: { eq: "customer-logos/kloeckner-i.png" }) {
      ...customerLogo
    }
    pulsepoint: file(relativePath: { eq: "customer-logos/pulsepoint.png" }) {
      ...customerLogo
    }
    raisin: file(relativePath: { eq: "customer-logos/raisin.png" }) {
      ...customerLogo
    }
    smunch: file(relativePath: { eq: "customer-logos/smunch.png" }) {
      ...customerLogo
    }
    hyperloopOne: file(relativePath: { eq: "customer-logos/virgin-hyperloop-one.png" }) {
      ...customerLogo
    }
  }
`;

export default IndexPage;
