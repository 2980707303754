import styled from 'styled-components';
import breakpoint from 'create-breakpoints';

const FeaturedSection = styled.div`
  background-color: #edf4fe;
  padding-top: 36px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  ${breakpoint.medium`
    margin-top: 220px;
    padding-bottom: 180px;
    background-color: transparent;
    position: relative;
  `};

  ${breakpoint.medium`
    &::before,
    &::after {
      background-color: #edf4fe;
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      transform-origin: bottom left;
      z-index: -1;
    }
    &::before {
      transform: skewY(-3.2deg) translateY(-60px);
    }
    &:after {
      transform: skewY(-5deg);
      opacity: 0.4;

      top: -5%;
      height: calc(100% + 5%);
    }
  `};
`;

export default FeaturedSection;
