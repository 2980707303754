import styled from 'styled-components';
import breakpoint from 'create-breakpoints';

const Card = styled.div`
  --spacing: 20px;

  border-radius: 3px;
  box-shadow: 0 10px 50px rgba(27, 31, 35, 0.1);
  padding: var(--spacing);

  ${breakpoint.large`
    --spacing: 40px;
  `}
`;

export default Card;
