import styled from 'styled-components';
import breakpoint from 'create-breakpoints';

const Timeline = styled.ol`
  list-style: none;
  padding: 0;
  position: relative;
  margin-left: 20px;

  ${breakpoint.medium`
    margin-left: 50px;
  `};

  ${breakpoint.large`
    margin-left: 30px;
  `};
`;

Timeline.Item = styled.li`
  padding-left: 36px;

  ${breakpoint.medium`
    padding-left: 50px;
    max-width: 40ch;
  `};

  ${breakpoint.large`
    padding-left: 70px;
    max-width: 40ch;
  `};

  &:not(:last-child) {
    border-left: 2px dashed var(--border-gray);
    padding-bottom: 50px;
  }
`;

Timeline.ItemIcon = styled.div.attrs({
  'aria-hidden': true
})`
  --size: 36px;

  background-color: var(--bg-blue-light);
  border-radius: 50%;
  color: var(--text-white);
  font-size: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: var(--size);
  height: var(--size);

  position: absolute;
  right: calc(100% - var(--size) / 2);

  ${breakpoint.medium`
    --size: 45px;
  `};

  ${breakpoint.large`
    --size: 55px;
  `};
`;

export default Timeline;
