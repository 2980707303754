import styled from 'styled-components';
import breakpoint from 'create-breakpoints';

const TextImage = styled.div`
  --gap: 50px;

  margin-bottom: var(--gap);

  ${breakpoint.medium`
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => props.reverse && 'row-reverse'};
  `};

  & > div {
    flex-basis: calc(50% - (var(--gap) / 2));
  }
`;

TextImage.Image = styled.div`
  --v-spacing: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--v-spacing);
  margin-bottom: var(--v-spacing);

  ${breakpoint.medium`
    --v-spacing: 0;
  `};

  img,
  svg {
    max-width: 100%;
  }
`;

TextImage.Body = styled.div`
  ${breakpoint.medium`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
`;

export default TextImage;
