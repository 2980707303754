import styled from 'styled-components';

const CustomerLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

CustomerLogos.Item = styled.div`
  --size: 120px;

  border-radius: 50%;
  background-color: var(--bg-gray-light);
  width: var(--size);
  height: var(--size);
  margin: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    mix-blend-mode: multiply;
  }
`;

export default CustomerLogos;
